import { observable, action, decorate, runInAction } from 'mobx'
import * as Api from '../services'
import BaseStore from './base'

class UsersStore extends BaseStore {
    isFetching = false
    isFetched = false

    size = 25
    total = 0
    users = []

    getUsers = async (page = 1, kind = null, query = null) => {
        this.isFetching = true
        const response = await Api.getUsers(this.getAuthToken(), page, this.size, kind, query)

        if (response.metadata.success) {
            runInAction(() => {
                this.users = response.data
                this.isFetching = false
                this.isFetched = true
                this.total = response.metadata.total
            })
        } else {
            runInAction(() => {
                this.isFetching = false
            })
        }
    }

    bulkDeleteUsers = async (userIds) => {
        this.isFetching = true
        const response = await Api.bulkDeleteUsers(this.getAuthToken(), userIds)
    
        if (response.metadata.success) {
            runInAction(() => {
                this.isFetching = false
            })
        }
    }

    reset = () => {
        this.page = 1
        this.size = 25
        this.users = []
        this.isFetched = false
        this.isFetching = false
    }
}

decorate(UsersStore, {
    size: observable,
    total: observable,
    users: observable,
    getUsers: action,
    reset: action,
    isFetched: observable,
    isFetching: observable,
})

export default new UsersStore()