import React from 'react'
import styles from './OrgList.module.css'
import { Link } from 'react-router-dom'

export default class OrgList extends React.Component {

    renderProject = (project) => {
        return (
            <div className={styles.project}>
                <div className={styles.name}>{project.name}</div>
                <span className={styles.stat}>f: {project.stats.totalFeedback}</span>
                <span className={styles.stat}>c: {project.stats.totalPosts}</span>
                <span className={styles.stat}>b: {project.stats.totalBoards}</span>
            </div>
        )
    }
    render() {
        return (
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Owner</th>
                        <th>Email address</th>
                        <th>Users</th>
                        <th>Feedback</th>
                        <th>Projects</th>
                    </tr>
                </thead>
                <tbody>
                {this.props.orgs.map(org => {
                    return (
                        <tr>
                            <td>
                                <Link to={`/org/${org.id}/`}>{org.id}</Link>
                            </td>
                            <td>
                                {org.owner.firstName} {org.owner.lastName}
                            </td>
                            <td>
                                {org.owner.emailAddress}
                            </td>
                            <td>{org.totalUsers}</td>
                            <td>{org.totalFeedback}</td>
                            <td>
                                {org.projects.map(project => this.renderProject(project))}
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
    }
}