import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'mobx-react';

import createBrowserHistory from 'history/createBrowserHistory';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { Router } from 'react-router';

// stores
import rootStore from './stores/rootStore'
import usersStore  from './stores/usersStore'
import authStore from './stores/authStore'
import projectsStore from './stores/projectsStore'
import dashboardStore from './stores/dashboardStore'
import orgsStore from './stores/orgsStore'
import singleOrgStore from './stores/singleOrgStore'

const routingStore = new RouterStore()
const browserHistory = createBrowserHistory();

const stores = {
    usersStore,
    authStore,
    routing: routingStore,
    projectsStore,
    dashboardStore,
    orgsStore,
    singleOrgStore,
}

rootStore.setStore('users', usersStore)
rootStore.setStore('auth', authStore)
rootStore.setStore('routing', routingStore)
rootStore.setStore('projects', projectsStore)
rootStore.setStore('dashboard', dashboardStore)
rootStore.setStore('orgs', orgsStore)
rootStore.setStore('singleOrg', singleOrgStore)

const history = syncHistoryWithStore(browserHistory, routingStore);

ReactDOM.render(
    <Provider {...stores}>
        <Router history={history}>
            <App />
        </Router>
    </Provider>
    , 
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
