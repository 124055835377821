import React from 'react'
import LoginForm from '../components/LoginForm'
import { inject, observer } from 'mobx-react'
import { when } from 'mobx'
import styles from './Login.module.css'

class LoginView extends React.Component {

    handleLogin = (values) => {
        this.props.authStore.authenticateUser(values.email, values.password)

        when(
            () => this.props.authStore.isLoggedIn, 
            () => {
                this.props.routing.push('/')
            }
        )
    }

    render() {
        return (
            <div className="pageRoot">
                <div className={styles.panel}>
                    <LoginForm loading={this.props.authStore.isLoggingIn} onLoginClick={this.handleLogin} />
                </div>
            </div>
        )
    }

}

export default inject('authStore', 'routing')(observer(LoginView))