import React from 'react'
import { withRouter } from 'react-router'
import { inject, observer } from 'mobx-react'
import styles from './DefaultLayout.module.css'

import Nav from '../components/Nav/Nav'

class DefaultLayout extends React.Component {

    componentDidUpdate(prevProps){
    }

    handleLogout = evt => {
        evt.preventDefault()
        this.props.authStore.logout()
    }

    render() {
        return (
            <div className={styles.root}>
                <Nav user={this.props.authStore.user} onLogout={this.handleLogout} />
                <div className={styles.content}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default withRouter(inject('authStore')(observer(DefaultLayout)))