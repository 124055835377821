import React from 'react'

export default class ProjectsList extends React.Component {
    render() {
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Boards</th>
                        <th>Changelog posts</th>
                        <th>Feedback</th>
                        <th>Portal</th>
                    </tr>
                </thead>
                {this.props.projects.map(project => {
                    return (
                        <tr>
                            <td>{project.id}</td>
                            <td>{project.name}</td>
                            <td>{project.stats.totalBoards}</td>
                            <td>{project.stats.totalPosts}</td>
                            <td>{project.stats.totalFeedback}</td>
                            <td>
                            </td>
                        </tr>
                    )
                })}
            </table>
        )
    }
}