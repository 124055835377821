import React from 'react'
import Modal from './Modal'

export default class ConfirmLink extends React.Component {

  state = {
    showModal: false
  }

  handleClick = evt => {
    evt.preventDefault()
    this.setState({ showModal: true })
  }

  handleCancel = () => {
    this.setState({ showModal: false })
  }

  handleConfirm = evt => {
    evt.preventDefault()
    this.setState({ showModal: false})
    this.props.onClick()
  }

  renderModal = () => {
    const style = {
      margin: '10px 0 0 0',
    }
    return (
      <Modal onClose={this.handleCancel} title={this.props.title}>
        {this.props.confirmMessage}
        <div style={style}>
          <button onClick={this.handleConfirm} className="btn btn-primary">Confirm</button> 
          <button onClick={this.handleCancel} className="btn btn-link">Cancel</button>
        </div>
      </Modal>
    )
  }

  render() {
    const { onClick, confirmMessage, title, ...props } = this.props
    return (
      <React.Fragment>
        <a href="#confirm-action" onClick={this.handleClick} {...props}>
          {this.props.children}
        </a>
        { this.state.showModal ? this.renderModal() : null }
      </React.Fragment>
    )
  }
}