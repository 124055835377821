import buildUrl from 'build-url'

export const buildApiUrl = (endpoint, params = null) => {
    const baseUrl = `${process.env.REACT_APP_BASE_SERVER_URL}`
    let url = null

    if (endpoint.slice(1, 6) === 'admin') {
        url = baseUrl + '/admin/api/v1.0'
        endpoint = endpoint.slice(6)
    } else {
        url = baseUrl + '/api/v1.0'
    }

    return buildUrl(url, {
        path: endpoint,
        ...params
    })
}

export const createHeaders = (authToken) => {
    const headers = {
        'Content-Type': 'application/json',
    }
    if (authToken) {
        headers['Authorization'] = authToken
    }
    return headers
}

export const handleResponse = (response) => {
    if (response.ok) {
        return response.json()
    }
  
    if (response.status >= 300 && response.status < 500) {
        return response.json()
    }
  
    return Promise.reject(response.statusText)
}

export const handleError = (errorMessage) => {
    let message = ''
    if (errorMessage instanceof Error) {
        message = errorMessage.message
    } else {
        message = errorMessage
    }
  
    return {
        metadata: {
            success: false,
            message: errorMessage,
            errors: [ { attribute: 'general', messages: [message] } ]
        }
    }
}

export const filterNulls = (params) => {
    const keys = Object.keys(params)
    let ret = {}
    for (let k of keys) {
        if (params[k] !== null) {
            ret[k] = params[k]
        }
    }

    return ret
}