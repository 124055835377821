
export default class BaseStore {

  rootStore = null

  setRootStore = (store) => {
    this.rootStore = store
  }

  getAuthToken = () => {
    return this.rootStore.stores.auth.user.authToken
  }
}
