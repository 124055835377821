import { observable, action, decorate } from 'mobx'

class RootStore {
    rootingStore = null
    stores = {}

    setStore(name, store) {
        this.stores[name] = store

        if (store.hasOwnProperty('setRootStore')) {
            store.setRootStore(this)
        }
    }
}

decorate(RootStore, {
    rootingStore: observable,
    stores: observable,
    setStore: action,
})

export default new RootStore()