import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Nav.module.css'

class Nav extends React.Component {
    render() {
        return (
            <div className={styles.root}>
                <div className={styles.wrapper}>
                    <header className="navbar">
                        <section className="navbar-section">
                            <Link to={'/'} className="navbar-brand mr-2">Makerkit Admin</Link>
                            <Link to={'/users'} className="btn btn-link">Users</Link>
                            <Link to={'/projects'} className="btn btn-link">Projects</Link>
                            <Link to={'/orgs'} className="btn btn-link">Organisations</Link>
                        </section>
                        <section className="navbar-section">
                            Welcome back {this.props.user.firstName}
                            <a href="#logout" id="logout" onClick={this.props.onLogout} className="btn btn-link">Logout</a>
                        </section>
                    </header>
                </div>
            </div>
        )
    }
}

export default Nav