import React from 'react'

/**
 * Merges a list of validation results. Useful when combining server side
 * validation errors and client-side errors
 *
 */
export const mergeValidationResults = (results) => {
  let newResult = { ok: true, errors: [] }
  for (let result of results) {
    if (!result || !result.errors) {
      continue
    }
    if (result.ok === false) {
      newResult.ok = false
    }
    newResult.errors = newResult.errors.concat(result.errors)
  }
  return newResult
}

/**
 * This renders client side errors from the validator
 *
 * @param {string} fieldName The name of field we want to render errors for
 * @param {object} validationResult The result from the validation
 */
export const renderErrorForField = (fieldName, validationResult) => {
  if (!validationResult || !validationResult.errors) {
    return
  }
  for (let fieldItem of validationResult.errors) {
    if (fieldItem.attribute === fieldName) {
      let message = fieldItem.messages ? fieldItem.messages[0] : fieldItem.message
      return (
          <p className="form-input-hint" style={{ color: '#e85600' }}>{message}</p>
      )
    }
  }
}



