import React from 'react'
import { inject, observer } from 'mobx-react'
import styles from './Dashboard.module.css'

import SignupGraph from '../components/SignupGraph'
import SignupByMonth from '../components/SignupByMonth/SignupByMonth'

class DashboardView extends React.Component {

    componentDidMount() {
        this.props.dashboardStore.getSignupGraphData()
    }

    render() {
        return (
            <div>
                <h2>Dashboard</h2>

                <div className={styles.table}>
                    <h4>Signups by day</h4>
                    <SignupGraph stats={this.props.dashboardStore.signupData.totalSignupsByDay} />
                </div>

                <div className={styles.table}>
                    <h4>Signups by month</h4>
                    <SignupByMonth stats={this.props.dashboardStore.signupData.totalSignupsByMonth} />
                </div>
            </div>
        )
    }
}


export default inject('dashboardStore')(observer(DashboardView))