import { buildApiUrl, createHeaders, handleError, handleResponse, filterNulls } from './helpers'

export const authenticateUser = async (emailAddress, password) => {
    const url = buildApiUrl('/auth/')

    return fetch(url, {
        method: 'POST',
        headers: createHeaders(),
        body: JSON.stringify({
            emailAddress: emailAddress,
            password: password,
            application: 'admin',
        })
    })
    .then(handleResponse)
    .catch(handleError)
}

export const getUser = async (token) => {
    const url = buildApiUrl('/user/')
    return fetch(url, {
        method: 'GET',
        headers: createHeaders(token),
    })
    .then(handleResponse)
    .catch(handleError)
}

export const getUsers = async (token, page, size, kind, query) => {
    const url = buildApiUrl('/admin/user/', {
        queryParams: filterNulls({ page, size, kind, query })
    })
    return fetch(url, {
        method: 'GET',
        headers: createHeaders(token),
    })
    .then(handleResponse)
    .catch(handleError)
}

export const getProjects = async (token, page, size) => {
    const url = buildApiUrl('/admin/project/', {
        queryParams: { page, size }
    })
    return fetch(url, {
        method: 'GET',
        headers: createHeaders(token),
    })
    .then(handleResponse)
    .catch(handleError)
}

export const getSignups = async (token) => {
    const url = buildApiUrl('/admin/overview/', {
        queryParams: { }
    })
    return fetch(url, {
        method: 'GET',
        headers: createHeaders(token),
    })
    .then(handleResponse)
    .catch(handleError)
}

export const getOrganisations = async (token, page, size) => {
    const url = buildApiUrl('/admin/organisation/', {
        queryParams: { page, size }
    })
    return fetch(url, {
        method: 'GET',
        headers: createHeaders(token),
    })
    .then(handleResponse)
    .catch(handleError)
}

export const bulkDeleteUsers = async (token, userIds) => {
    const url = buildApiUrl('/admin/user/bulkdelete/')
    return fetch(url, {
        method: 'POST',
        headers: createHeaders(token),
        body: JSON.stringify({
            userids: userIds
        })
    })
    .then(handleResponse)
    .catch(handleError)
}

export const getOrg = async (token, orgId) => {
    const url = buildApiUrl(`/admin/organisation/${orgId}/`)
    return fetch(url, {
        method: 'GET',
        headers: createHeaders(token),
    })
    .then(handleResponse)
    .catch(handleError)
}

export const getOrgUsers = async (token, orgId) => {
    const url = buildApiUrl(`/admin/organisation/${orgId}/users/`)
    return fetch(url, {
        method: 'GET',
        headers: createHeaders(token),
    })
    .then(handleResponse)
    .catch(handleError)
}

export const getOrgProjects = async (token, orgId) => {
    const url = buildApiUrl(`/admin/organisation/${orgId}/projects/`)
    return fetch(url, {
        method: 'GET',
        headers: createHeaders(token),
    })
    .then(handleResponse)
    .catch(handleError)
}
