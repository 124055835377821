import React, { Component } from 'react';
import { inject, observer } from 'mobx-react'
import { Redirect, withRouter } from 'react-router'

class LoginRequired extends Component {

    render() {
        if (this.props.authStore.ready === false) {
            return <span>Loading...</span>
        }

        if (!this.props.authStore.isLoggedIn) {
            return <Redirect to="/login" />
        }

        return this.props.children
    }
}

export default withRouter(inject('authStore', 'routing')(observer(LoginRequired)))
