import React from 'react'
import { inject, observer } from 'mobx-react'
import Pagination from '../components/Pagination'
import OrgList from '../components/OrgList'

export class OrganisationsView extends React.Component {

    state = {
        page: 1
    }

    componentDidMount() {
        this.props.orgsStore.getOrgs(this.state.page)
    }

    handlePagination = (page) => {
        this.setState({ page: page })
        this.props.orgsStore.getOrgs(page)
    }

    render() {
        return (
            <div>
                <h2>Organisations <small>{this.props.orgsStore.total}</small></h2>
                <OrgList orgs={this.props.orgsStore.orgs} />
                <Pagination
                    page={this.state.page}
                    size={this.props.orgsStore.size}
                    total={this.props.orgsStore.total}
                    onPageClick={this.handlePagination}
                />
            </div>
        )
    }
}

export default inject('orgsStore')(observer(OrganisationsView))