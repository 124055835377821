import React from 'react'
import styles from './UsersList.module.css'
import { format }from 'date-fns'
import produce from 'immer'
import ConfirmLink from './Confirm'
import { Link } from 'react-router-dom'

class UsersList extends React.Component {

    state = {
        selectedAccounts: [],
    }

    handleAddToSelected = user => {
        return evt => {
            const state = produce(this.state, draft => {
                if (this.isChecked(user)) {
                    draft.selectedAccounts = draft.selectedAccounts.filter(id => id !== user.id)
                } else {
                    draft.selectedAccounts.push(user.id)
                }
            })
            this.setState(state) 
        }
    }

    isChecked = user => this.state.selectedAccounts.indexOf(user.id) >= 0 ? true : false

    handleSelectAll = evt => {
        evt.preventDefault()
        this.setState({
            selectedAccounts: this.props.users.map(user => user.id)
        })
    }

    handleSelectNone = evt => {
        evt.preventDefault()
        this.setState({
            selectedAccounts: [],
        })
    }

    handleNuke = () => {
        this.props.onDelete(this.state.selectedAccounts)
    }

    selectedUserActions = () => {
        const deleteStyle = {
            color: '#f00'
        }
        return (
            <div className={styles.actions}>
                <span>Selected {this.state.selectedAccounts.length}</span>
                <ConfirmLink
                    style={deleteStyle}
                    confirmMessage={'Are you sure you want to nuke these users?'}
                    onClick={this.handleNuke}
                    title={'Are you sure?'}
                >Nuke</ConfirmLink>
            </div>
        )
    }

    render() {
        if (this.props.users.length === 0) {
            return <span>No users found!</span>
        }

        return (
            <div className={styles.root}>
                <div className={styles.controls}>
                    <span>Select</span>
                    <a href="#" onClick={this.handleSelectAll}>All</a>
                    <a href="#" onClick={this.handleSelectNone}>None</a>
                    { this.state.selectedAccounts.length > 0 ? this.selectedUserActions() : null }
                </div>
                <table className="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Id</th>
                            <th>Org</th>
                            <th>Name</th>
                            <th>Kind</th>
                            <th>Email</th>
                            <th>Auth</th>
                            <th>Joined</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.props.users.map(user => (
                        <tr key={user.id}>
                            <td>
                                <input type="checkbox" checked={this.isChecked(user)} onChange={this.handleAddToSelected(user)} />
                            </td>
                            <td>{user.id}</td>
                            <td><Link to={`/org/${user.organisationId}`}>{user.organisationId}</Link></td>
                            <td>{user.firstName + ' ' + user.lastName}</td>
                            <td>{user.kind}</td>
                            <td>{user.emailAddress}</td>
                            <td>{user.authScheme}</td>
                            <td>{format(new Date(user.createdAt * 1000), 'd/MM/yyyy k:m')}</td>
                            <td>
                                { /* eslint-disable-next-line react/jsx-no-target-blank */}
                                <a href={'https://app.makerkit.co/token-login?token=' + user.authToken} target="_blank" className="btn btn-sm btn-primary">Login</a>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default UsersList