import { observable, action, decorate, runInAction } from 'mobx'
import * as Api from '../services'
import BaseStore from './base'

class ProjectsStore extends BaseStore {

    projects = []
    isFetching = false
    isFetched = false
    error = null

    size = 25
    total = 0

    getProjects = async (page = 1) => {
        this.isFetching = true
        const response = await Api.getProjects(this.getAuthToken(), page, this.size)

        if (response.metadata.success) {
            runInAction(() => {
                this.isFetching = false
                this.isFetched = true
                this.projects = response.data
                this.total = response.metadata.total
            })
        } else {
            runInAction(() => {
                this.isFetching = false
                this.error = response.metadata
            })
        }
    }    

}

decorate(ProjectsStore, {
    isFetched: observable,
    isFetching: observable,
    getProjects: action,
    size: observable,
    projects: observable,
    total: observable,
    error: observable,
})

export default new ProjectsStore()

