import { observable, action, decorate, runInAction } from 'mobx'
import * as Api from '../services'
import BaseStore from './base'

class OrgsStore extends BaseStore {

    orgs = []
    isFetching = false
    isFetched = false
    error = null

    size = 25
    total = 0

    getOrgs = async (page = 1) => {
        this.isFetching = true
        const response = await Api.getOrganisations(this.getAuthToken(), page, this.size)

        if (response.metadata.success) {
            runInAction(() => {
                this.isFetching = false
                this.isFetched = true
                this.orgs = response.data
                this.total = response.metadata.total
            })
        } else {
            runInAction(() => {
                this.isFetching = false
                this.error = response.metadata
            })
        }
    }    

}

decorate(OrgsStore, {
    isFetched: observable,
    isFetching: observable,
    getProjects: action,
    size: observable,
    orgs: observable,
    total: observable,
    error: observable,
})

export default new OrgsStore()

