import React from 'react'
import { inject, observer } from 'mobx-react'
import AttributeDisplayer from '../components/AttributeDisplayer'
import UsersList from '../components/UsersList'
import Pagination from '../components/Pagination'

class UsersView extends React.Component {

    state = {
        page: 1,
        kind: null,
        userSearchQuery: '',
    }

    componentDidMount() {
        this.props.usersStore.getUsers(this.state.page, this.state.kind)
    }

    handlePagination = (page) => {
        this.setState({ page: page })
        this.props.usersStore.getUsers(page, this.state.kind)
    }

    handleFilterByKind = kind => {
        return (evt) => {
            evt.preventDefault()
            this.setState({ kind: kind })
            this.props.usersStore.getUsers(this.state.page, kind)
        }
    }

    handleUserSearchQueryChange = evt => {
        this.setState( {
            userSearchQuery: evt.target.value
        })
    }

    handleUserSearch = evt => {
        evt.preventDefault()
        this.props.usersStore.getUsers(this.state.page, this.state.kind, this.state.userSearchQuery)
    }

    handleUserDelete = (selectedUserIds) => {
        this.props.usersStore.bulkDeleteUsers(selectedUserIds)
    }

    render() {
        return (
            <div>
                <h2>Users <small><AttributeDisplayer store={this.props.usersStore} extractor={(store) => store.total}  /></small></h2>

                <p>
                    Filter by:&nbsp;
                    <button className="btn btn-link" onClick={this.handleFilterByKind('TENANT')}>Tenants</button> 
                    <button className="btn btn-link" onClick={this.handleFilterByKind('SUB_TENANT')}>Subtenants</button> 
                    <button className="btn btn-sm btn-link" onClick={this.handleFilterByKind(null)}>(Clear)</button>
                </p>

                <form onSubmit={this.handleUserSearch}>
                    <input value={this.state.userSearchQuery} onChange={this.handleUserSearchQueryChange} className="form-input" type="text" name="email" placeholder='Search for user...' />
                </form>

                <UsersList 
                    total={this.props.usersStore.total}
                    size={this.props.usersStore.size}
                    page={this.state.page}
                    users={this.props.usersStore.users}
                    onPageChange={this.handlePagination}
                    onDelete={this.handleUserDelete}
                />
                <Pagination
                    page={this.state.page}
                    total={this.props.usersStore.total}
                    size={this.props.usersStore.size}
                    onPageClick={this.handlePagination}
                />
            </div>
        )
    }
}

export default inject('usersStore')(observer(UsersView))