import React from 'react'
import PropTypes from 'prop-types'
import Chart from 'chart.js'

const styles = {
    root: {
        width: '100%',
        height: '300px',
        position: 'relative',
    }
}

export default class SignupGraph extends React.Component {

  static propTypes = {
    stats: PropTypes.array,
  }

  constructor(props) {
    super(props)
    this.chartNode = null
  }

  componentDidUpdate() {
    this.createChart()
  }

  /**
   * Get the data for the bar chart
   *
   */
  getChartData = () => {
    let data = []
    for (let point of this.props.stats) {
      data.push({
        x: new Date(point.date*1000),
        y: point.total
      })
    }

    return data
  }

  createChart = () => {
    let chartData = this.getChartData()
    this.chart = new Chart(this.chartNode, {
      type: 'bar',
      data: {
        labels: chartData.map(point => point.x),
        datasets: [{
          label: 'New accounts',
          data: chartData,
          borderWidth: 1,
          backgroundColor: '#5755d9'
        }]
      },
      options: {
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              unit: 'day',
              round: 'day',
              displayFormat: {
                day: 'MMM D',
              }
            }
          }],
          yAxes: [{
            ticks: {
              stepSize: 1
            }
          }]
        }
      }
    })
  }

  render() {
    return (
      <div className={styles.root}>
        <canvas style={{ height: '300px', width: '100%' }} ref={(ref) => this.chartNode = ref}></canvas>
      </div>
    )
  }
}
