import React from 'react'
import PropTypes from 'prop-types'

export default class Pagination extends React.Component {

    static propTypes = {
        page: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
        size: PropTypes.number.isRequired,
        onPageClick: PropTypes.func.isRequired,
    }

    getPages = (leftEdge = 2, leftCurrent = 3, rightCurrent = 3, rightEdge = 2) => {
        let last = 0
        let totalPages = this.getTotalPages()
        let ret = []
        for (let i = 1; i < totalPages + 1; i++) {
            if (i <= leftEdge || (i > this.props.page - leftCurrent - 1 && i < this.props.page + rightCurrent) || i > totalPages - rightEdge) {
                if (last + 1 !== i) {
                    ret.push(null)
                }
                ret.push(i)
                last = i
            }
        }
        return ret
    }

    getTotalPages = () => {
        return Math.ceil(this.props.total / this.props.size)
    }

    createPageClickHandler = (page) => {
        return (evt) => {
            evt.preventDefault()
            this.props.onPageClick(page)
        }
    }

    renderLinks = () => {
        let nodes = []
        for (let page of this.getPages()) {
            if (page) {
                if (page !== this.props.page) {
                    nodes.push(
                        <li className="page-item" key={page}>
                            <a href={`#page-${page}`} id={`page-${page}`} className="button" onClick={this.createPageClickHandler(page)}>{page}</a>
                        </li>
                    )
                } else {
                    nodes.push(
                        <li key={page} className="page-item active">
                            <a href={`#page-${page}`} id={`page-${page}`}>{page}</a>
                        </li>
                    )
                }
            } else {
                nodes.push(
                    <li key={'spacer'} className="page-item"><span>...</span></li>
                )
            }
        }
        return nodes
    }

    render() {
        // no pagination required
        if (this.getTotalPages() < 2) {
            return <div></div>
        }

        return (
            <ul className="pagination">
                <li className="page-item"><a href="#prev" id="prev" disabled={this.props.page === 1 ? true : false} onClick={this.createPageClickHandler(this.props.page - 1)}>Previous</a></li>
                {this.renderLinks()}
                <li className="page-item"><a href="#next" id="next" disabled={this.getTotalPages() === this.props.page ? true : false} onClick={this.createPageClickHandler(this.props.page + 1)}>Next page</a></li>
            </ul>
        )
    }
}