import React from 'react'
import { inject, observer } from 'mobx-react'
import ProjectsList from '../components/ProjectsList'
import Pagination from '../components/Pagination'

export class ProjectsView extends React.Component {

    state = {
        page: 1
    }

    componentDidMount() {
        this.props.projectsStore.getProjects(this.state.page)
    }

    handlePagination = (page) => {
        this.setState({ page: page })
        this.props.projectsStore.getProjects(page)
    }

    render() {
        return (
            <div>
                <h2>Projects <small>{this.props.projectsStore.total}</small></h2>
                <ProjectsList projects={this.props.projectsStore.projects} />
                <Pagination
                    page={this.state.page}
                    size={this.props.projectsStore.size}
                    total={this.props.projectsStore.total}
                    onPageClick={this.handlePagination}
                />
            </div>
        )
    }
}

export default inject('projectsStore')(observer(ProjectsView))