import { observable, action, decorate, runInAction } from 'mobx'
import * as Api from '../services'
import BaseStore from './base'

class DashboardStore extends BaseStore {

    isFetchingSignupGraph = false
    isFetchedSignupGraph = false
    signupData = {
        totalSignupsByDay: []
    }

    getSignupGraphData = async () => {
        this.isFetchingSignupGraph = true
        const response = await Api.getSignups(this.getAuthToken())

        if (response.metadata.success) {
            runInAction(() => {
                this.signupData = response.data
                this.isFetchedSignupGraph = true
            })
        }

        runInAction(() => {
            this.isFetchingSignupGraph = false
        })
    }
}

decorate(DashboardStore, {
    isFetchedSignupGraph: observable,
    isFetchingSignupGraph: observable,
    getSignupGraphData: action,
    signupData: observable,
})

export default new DashboardStore()