import React from 'react'
import PropTypes from 'prop-types'
import styles from './Modal.css'
import classNames from 'classnames'

export default class Modal extends React.Component {

  static propTypes = {
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    overflow: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    size: PropTypes.string,
  }

  static defaultProps = {
    overflow: 'visible',
    size: 'small',
  }

  handleClose = evt => {
    evt.preventDefault()
    this.props.onClose()
  }

  render() {
    let rootStyle = {}
    let bodyStyle = {}
    let modalClass = classNames(styles.root, 'modal', 'active', {
      'modal-lg': this.props.size === 'large',
      [styles.small]: this.props.size === 'small',
    })

    if (this.props.overflow === 'visible') {
      rootStyle = { overflow: 'visible' }
      bodyStyle = { maxHeight: '100%', overflowY: 'visible' }
    }

    return (
      <div className={modalClass} style={rootStyle}>
        <div className="modal-overlay"></div>
        <div className="modal-container">
          { this.props.title ?
            <div className="modal-header">
              <button className="btn btn-clear float-right" onClick={this.handleClose}></button>
              <div className="modal-title h5">{this.props.title}</div>
            </div>
            :
              <div className={styles.clear}>
                <button className="btn btn-clear float-right" onClick={this.handleClose}></button>
              </div>
          }

          
          <div className="modal-body" style={bodyStyle}>
            <div className="content">
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
