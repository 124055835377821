import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router'

import 'spectre.css'
import './App.css';

import DefaultLayout from './views/DefaultLayout'
import LoginRequired from './components/LoginRequired'
import LoginView from './views/Login'
import DashboardView from './views/Dashboard'
import UsersView from './views/Users';
import ProjectsView from './views/Projects'
import OrganisationsView from './views/Orgs'
import SingleOrgView from './views/SingleOrg'

class App extends Component {
  render() {
    return (
      <div className="App">
        <Switch>
          <Route exact path='/login' component={LoginView} />
          <Route path='/'>
            <LoginRequired>
              <DefaultLayout>
                <Switch>
                  <Route exact path='/' component={DashboardView} />
                  <Route exact path='/users' component={UsersView} />
                  <Route exact path='/projects' component={ProjectsView} />
                  <Route exact path='/orgs' component={OrganisationsView} />
                  <Route exact path='/org/:id' component={SingleOrgView} />
                </Switch>
              </DefaultLayout>
            </LoginRequired>
          </Route>
        </Switch>
      </div>
    );
  }
}

export default withRouter(App)
