import { observable, action, decorate, runInAction } from 'mobx'
import * as Api from '../services'
import BaseStore from './base'

class SingleOrgStore extends BaseStore {

    org = {}
    projects = []
    users = []

    isFetchingOrg = false
    isFetchedOrg = false

    isFetchingUsers = false
    isFetchedUsers = false

    isFetchingProjects = false
    isFetchedProjects = false

    totalUsers = 0

    getOrg = async (id) => {
        this.isFetchingOrg = true
        const response = await Api.getOrg(this.getAuthToken(), id)
        if (response.metadata.success) {
            runInAction(() => {
                this.isFetchedOrg = true
                this.isFetchingOrg = false
                this.org = response.data
            })
        } else {
            runInAction(() => {
                this.isFetchingOrg = false
            })
        }
    }

    getUsers = async (id, page=1) => {
        this.isFetchingUsers = true
        const response = await Api.getOrgUsers(this.getAuthToken(), id)
        if (response.metadata.success) {
            runInAction(() => {
                this.isFetchedUsers = true
                this.isFetchingUsers = false
                this.users = response.data
                this.totalUsers = response.metadata.total
            })
        } else {
            runInAction(() => {
                this.isFetchingUsers = false
            })
        }
    }

    getProjects = async (orgId) => {

    }

}

decorate(SingleOrgStore, {
    // obseravable
    org: observable,
    projects: observable,
    users: observable,

    isFetchingOrg: observable,
    isFetchedOrg: observable,

    isFetchingUsers: observable,
    isFetchedUsers: observable,

    isFetchingProjects: observable,
    isFetchedProjects: observable,

    totalUsers: observable,

    // actions
    getProjects: action,
    getOrg: action,
    getUsers: action,
})

export default new SingleOrgStore()

