import React from 'react'
import PropTypes from 'prop-types'
import styles from './LoginForm.css'
import { renderErrorForField } from '../../utils/misc'
import classNames from 'classnames'

export default class LoginForm extends React.Component {

  static propTypes = {
    onLoginClick: PropTypes.func.isRequired,
    validation: PropTypes.object,
    loading: PropTypes.bool.isRequired,
  }

  state = {
    email: '',
    password: ''
  }

  componentDidMount() {
    this.emailInput.focus()
  }

  handleLoginClick = (evt) => {
    evt.preventDefault()
    this.props.onLoginClick({
      email: this.state.email,
      password: this.state.password
    })
  }

  handleEmailChange = (evt) => {
    this.setState({
      email: evt.target.value
    })
  }

  handlePasswordChange = (evt) => {
    this.setState({
      password: evt.target.value
    })
  }

  handleFormSubmit = (evt) => {
    evt.preventDefault()
    this.props.onLoginClick({
      email: this.state.email,
      password: this.state.password
    })
  }

  render() {
    let btnClassName = classNames({
      'btn': true,
      'btn-primary': true,
      'btn-block': true,
      'btn-lg': true,
      'loading': this.props.loading,
    })
    return (
      <div className={styles.root}>
        <form onSubmit={this.handleFormSubmit}>
          <div className="form-group">
            <label className="form-label">Email</label>
            <input ref={ref => this.emailInput = ref} id="email" name="email" value={this.state.email} onChange={this.handleEmailChange} className="form-input input-lg" type="email" placeholder="Email" />
            {renderErrorForField('email', this.props.validation)}
          </div>
          <div className="form-group">
            <label className="form-label">Password</label>
            <input id="password" name="password" value={this.state.password} onChange={this.handlePasswordChange} className="form-input input-lg" type="password" placeholder="Your password" />
            {renderErrorForField('password', this.props.validation)}
            {renderErrorForField('api_error', this.props.validation)}
          </div>
          <div className="form-group">
            <button className={btnClassName} type="submit" onClick={this.handleLoginClick}>Login</button>
          </div>
        </form>
      </div>
    )
  }
}

