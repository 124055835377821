import { observable, action, decorate, runInAction } from 'mobx'
import * as Api from '../services'
import Cookies from 'js-cookie'

class AuthStore {
    rootStore = null

    isLoggedIn = false
    isLoggingIn = false
    triedCookieAuth = false
    ready = false

    user = null
    error = null

    constructor(){
        this.tryAuthWithCookieData()
    }

    setRootStore = (store) => {
        this.rootStore = store
    }

    logout = () => {
        this.isLoggedIn = false
        this.user = null
        Cookies.remove('auth')
    }

    tryAuthWithCookieData = async () => {
        if (Cookies.get('auth') === undefined) {
            this.ready = true
            return
        }
        const storedAuth = JSON.parse(Cookies.get('auth'))
        const response = await Api.getUser(storedAuth.authToken)

        if (response.metadata.success) {
            runInAction(() => {
                this.user = response.data
                this.isLoggedIn = true
            })
        }

        runInAction(() => {
            this.triedCookieAuth = true
            this.ready = true
        })
    }

    authenticateUser = async (emailAddress, password) => {
        this.isLoggingIn = true
        const response = await Api.authenticateUser(emailAddress, password)

        runInAction(() => {
            this.isLoggingIn = false
        })

        if (!response.metadata.success) {
            runInAction(() => {
                this.error = response.metadata
            })
            return
        } else {
            runInAction(() => {
                this.isLoggedIn = true
                this.user = response.data
            })
            Cookies.set('auth', JSON.stringify(this.user))
        }
    }

}

decorate(AuthStore, {
    isLoggedIn: observable,
    isLoggingIn: observable,
    user: observable,
    authenticateUser: action,
    error: observable,
    loadFromCookieData: action,
    triedCookieAuth: observable,
    logout: action,
    ready: observable,
})

export default new AuthStore()