import React from "react"


const SignupByMonth = ({ stats }) => {
  if (!stats) {
    return null
  }
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Date</th>
          <th>Total</th>
        </tr>
      </thead>
      {stats.map(row => {
        return (
          <tr>
            <td>{row.month} {row.year}</td>
            <td>{row.total}</td>
          </tr>
        )
      })}
    </table>
  )
}

export default SignupByMonth