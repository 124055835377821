import React from 'react'
import { inject, observer } from 'mobx-react'
import { format } from 'date-fns'

export class SingleOrgView extends React.Component {

    componentDidMount() {
        const orgId = this.props.match.params.id
        this.props.singleOrgStore.getOrg(orgId)
        this.props.singleOrgStore.getUsers(orgId, 1)
    }

    getLoginUrl = (user) => {
        return 'https://app.makerkit.co/token-login?token=' + user.authToken
    }

    renderProjects = () => {
        const rows = this.props.singleOrgStore.org.projects.map(project => {
            return (
                <tr>
                    <td>{project.id}</td>
                    <td>{project.name}</td>
                </tr>
            )
        })
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        )
    }

    renderUsers = () => {
        const rows = this.props.singleOrgStore.users.map(user => {
            return (
                <tr>
                    <td>{user.id}</td>
                    <td>{user.name}</td>
                    <td>{user.emailAddress}</td>
                    <td>{user.kind}</td>
                    <td>{user.authScheme}</td>
                    <td>{format(new Date(user.createdAt * 1000), 'd/MM/yyyy k:m')}</td>
                    <td>
                        { /* eslint-disable-next-line react/jsx-no-target-blank */}
                        <a href={this.getLoginUrl(user)} target="_blank" className="btn btn-sm btn-primary">Login</a>
                    </td>
                </tr>
            )
        })
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Kind</th>
                        <th>Auth</th>
                        <th>Date joined</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        )
    }

    render() {
        if (!this.props.singleOrgStore.isFetchedOrg) {
            return 'Fetching org...'
        }

        const owner = this.props.singleOrgStore.org.owner

        return (
            <div>
                <h1>Org: {this.props.singleOrgStore.org.id}</h1>
                <p>
                    <b>ID:</b> {owner.id} <br/>
                    <b>Owner:</b> {owner.name} <br />
                    <b>Email:</b> {owner.emailAddress} <a href={this.getLoginUrl(owner)} target="_blank" className="btn btn-sm btn-primary">Login</a>
                </p>


                <h3>Projects</h3>
                {this.renderProjects()}

                <br />

                <h3>Users ({this.props.singleOrgStore.totalUsers})</h3>
                {this.renderUsers()}

            </div>
        )
    }
}

export default inject('singleOrgStore')(observer(SingleOrgView))